.ui.checkbox .box:after,
.ui.checkbox label:after,
.checkboxcustom {
  opacity: 1;
  color: white !important;
  background: #21c8aa;
  border-radius: 3px;
  font-size: 10px;
}

.checkboxcustom {
  top: 0px !important;
  left: 3px !important;
  position: absolute !important;
  transform: scale(1.5) !important;
  width: 0px !important;
}

.ui.toggle.checkbox input:checked ~ label,
.box,
.ui.toggle.checkbox input:focus:checked ~ label,
.ui.toggle.checkbox label {
  color: #003458 !important;
  font-size: 1rem !important;
}

.candidate_name {
  background: #003458;
  color: white;
  border-radius: 12px;
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
  font-size: 5em;
  height: 100%;
  margin-left: 10%;
}

.filter-top-padding {
  padding-top: 10px;
}


.page-with-filter-open {
  max-width: 1150px;
  width: 100%; 
  transition: width 0.3s;
}

.pushable {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  padding: 0em !important;
}

@media screen and (max-width: 1100px) {
  .show-filter-button{
    display: block;

  }

  .heelo{
    background: red;
  }
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #D9D9D9;
  color: rgba(0, 52, 88, 1);
}

.filter-header p {
  font-family: Matter;
font-weight: 500;
line-height: 33px;
letter-spacing: 0.6968750357627869px;
text-align: left;

  font-weight: 500;
}
.savedSearch {
  border-bottom: 1px solid #D9D9D9;
  
}

.dropdown-custom{
  margin-bottom: 1rem;
  border: 1px solid #8080803d;
  padding: 9px 13px;
  width: 100%;
  justify-content: space-between;
  display: flex !important;
  border-radius: 5px;
}

.LinksButton img{
  height: 22px !important;
}

.talent-candidatePopup{
  color: #645B5B !important;
}

.ui.sortable.table thead th{
  border-left: none !important;
}

.header-box {
  margin: 0;
  padding: 0.5em 1em;
  color: #645B5B;
  cursor: pointer;
}

.header-active {
/* border-radius: 12px 12px 0px 0px; */
/* border: 1px solid #B3B3B3; */
border-bottom: #003458 2px solid;
background: white !important;
color: #003458 !important;
}

.table-active {
  color: #003458;
  border-bottom: 2px solid #21C8AA;
  font-weight: 500 !important;
}

.talent-pool-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.talent-pool-header-tab {
  display: flex;
}

.talent-pool-header-banner {
  display: flex;
}


@media screen and (max-width: 738px) {
  .talent-pool-header {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
  .talent-pool-header-tab {
    order: 2;
  }
  .talent-pool-header-banner {
    order: 1;
  }
}
/* new talentcard view css start */
.chip-container {
  width: 100%;
  overflow-x: hidden;
}

.allpool-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-left: 18px;
}
.fire-icon {
  position: absolute;
  top: -25px;
  left: 0;
  background: #F7F7F7;
  padding: 4px;
  border-radius: 10%;
  border: 1px solid #61605F;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.time-badge {
  position: absolute;
  top: -25px;
  right: 0;
  background: #CAECFF;
  color: black;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #61605F;
  z-index: -1;

}
.mypool-chip {
  height: auto;
  min-height: 40px;
  margin:  0.8rem !important;
  border-radius: 8px;
  border: 1px solid #5F6061!important;
  background: #FFF !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.talentpool-table {
  display: grid;
  padding: 0 1rem;
  row-gap: 8px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #B3B3B3;
  /* background: #F8FBFF; */
}

.table-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #B3B3B3;
  text-transform: uppercase;
  color: #645B5B;
  font-weight: 500;
  font-size: 0.9rem;
  /* margin: auto 0; */
}

.table-cell {
  display: flex;
  /* border: 1px solid #000; */
  /* text-transform: capitalize; */
  border-bottom: 1px solid #B3B3B3;
}

.table-cell h4 {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.table-button {
  border: 1px solid #003458 !important;
  background-color: #CAECFF !important;
  color: #003458 !important;
  border-radius: 8px !important;
  width: 110px !important;
}

.table-button-container {
  align-items: flex-end;
  row-gap: 5px;
}

.table-photo {
  width: 60px;
  height: auto;
}

.row1 {
  justify-content: flex-start;
  align-items: center;
  border-bottom: none;
  margin-top: 10px;
}

.share {
  background-color: #fff !important;
}

.skills-list {
  display: inline-flex;
  gap: 8px;
  flex-shrink: 0;
  list-style: none;
  width: 100%;
  overflow: hidden;
  flex-flow: wrap;
}

.skills-list .chip {
  height: auto;
padding: 2px 8px;
justify-content: center;
align-items: center;
border-radius: 8px;
background: #f0efef;
text-transform: capitalize;
font-size: 13px;
font-weight: 500;
letter-spacing: 0.521px;
color: var(--text);
/* border: #003458 0px solid; */
}

.role-designation {
  margin-bottom: 6px;
  letter-spacing: 0.521px;
  font-weight: 400;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-start {
  align-items: flex-start;
}

.p-2 {
  padding: 1rem;
}

.row-span-2 {
  grid-row: span 2;
  margin-top: 10px;
}

.col-span-2 {
  grid-column: span 2;
}

.col-span-4 {
  grid-column: span 4;
}

.secondary-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 75%;
}

.pool-action-buttons {
  display: flex;
  justify-content: center;
  padding: 1rem;

}

.show_qrata_button {
  border: 1px solid #003458 !important;
  background-color: transparent !important;
  color: #003458 !important;
}

.banner-box {
  border-radius: 8px;
  border: 1px solid #B3B3B3;
  background: #F4F4F4;
  padding: 5px;
  margin: 0 10px;
  position: relative
}

.call-banner {
  padding-left: 20px ;
}

.call-banner img {
  position: absolute;
  top: -2px;
  left: -20px;
  width: 35px;
  height: 35px;
}
/* new talentcard view css end */


 
@media (min-width: 1101px) {
  .filter-container {
    display: block; /* Show the filter container */
  }
  .clndr-calendars-segment.clndr-floating{
    overflow-x: scroll !important;
    width: 150% !important;
  }
  
  .show-filter-button {
    display: none; /* Hide the button to show the filter */
  }
}


@media (min-width:1100px) {
  .filterhide{
    display: none !important;
  }
}

